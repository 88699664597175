import ErrorPageCanvas from '../pages/error/ErrorPageCanvas';
import Button from '@/components/shared/Button';
import Link from '@/components/shared/Link';

interface Props {
    errorNumber: number;
}

const dict: Record<number, string> = {
    404: 'Такой страницы не&nbsp;существует, перейдите на&nbsp;главную',
    500: 'Внутренняя ошибка сервера',
};

const ErrorPageLayout = ({ errorNumber }: Props) => {
    return (
        <div className="error-page-layout">
            <div className="error-page-content">
                <div
                    className="error-page-content__text text-l"
                    dangerouslySetInnerHTML={{ __html: dict[errorNumber] }}
                />
                <Button geometryVariant="mustache" tag={Link} href="/">
                    На главную
                </Button>
            </div>
            <ErrorPageCanvas />
        </div>
    );
};

export default ErrorPageLayout;
