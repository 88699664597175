import { useAppReadyState } from '@/atoms/app-ready';
import { COLORS } from '@/colors';
import useElementSize from '@/hooks/use-element-size';
import { drawCircle } from '@/utils/canvas';
import { lerp } from '@/utils/lerp';
import { degToRad } from '@/utils/math';
import { mergeRefs } from '@/utils/merge-refs';
import { viewport } from '@/utils/viewport';
import { useAnimationFrame } from 'framer-motion';
import { useEffect, useMemo, useRef, useState } from 'react';
import { constSelector } from 'recoil';

const clockOffset = 90;
const radius = Math.sqrt(Math.pow(viewport.width, 2) + Math.pow(viewport.height, 2)) / 2;
const degsDict = [21, 174, 316];

const ErrorPageCanvas = () => {
    const ref = useRef<HTMLCanvasElement | null>(null);
    const [dpr, setDpr] = useState(1);
    const [ctx, setCtx] = useState<CanvasRenderingContext2D | null>(null);
    const [appReady] = useAppReadyState();
    const [canvasRef, size] = useElementSize<HTMLCanvasElement>();
    const startTime = useRef(0);
    const width = size.width * dpr;
    const height = size.height * dpr;
    const dimension = Math.min(width, height) * (2 / dpr);
    const pointerX = useRef(viewport.width / 2);
    const wheelLerpedPointerX = useRef(pointerX.current);
    const date = useMemo(() => new Date(), []);

    const getResponsiveDimension = (value: number) => {
        return (value / 1920) * dimension;
    };

    const centerX = width / 2;
    const centerY = height / 2;

    useEffect(() => {
        if (ref.current) {
            setCtx(ref.current.getContext('2d'));
        }
    }, [ctx]);

    useEffect(() => {
        const onResize = () => {
            setDpr(Math.min(window.devicePixelRatio, 2));
        };

        onResize();
        window.addEventListener('resize', onResize);

        return () => window.removeEventListener('resize', onResize);
    }, []);

    useEffect(() => {
        const onMousemove = (event: MouseEvent) => {
            pointerX.current = event.clientX;
        };

        document.addEventListener('mousemove', onMousemove);

        return () => {
            document.removeEventListener('mousemove', onMousemove);
        };
    }, []);

    useAnimationFrame((t) => {
        const time = t * 0.00015;
        if (appReady && ctx) {
            wheelLerpedPointerX.current = lerp(wheelLerpedPointerX.current, pointerX.current, 0.1);

            ctx.clearRect(0, 0, width, height);

            ctx.lineWidth = dpr;
            ctx.strokeStyle = COLORS.WHITE;

            // Часовая стрелка
            ctx.beginPath();
            ctx.moveTo(centerX, centerY);
            ctx.lineTo(
                centerX + Math.cos(degToRad(date.getHours() * 30 - clockOffset)) * getResponsiveDimension(286) * dpr,
                centerY + Math.sin(degToRad(date.getHours() * 30 - clockOffset)) * getResponsiveDimension(286) * dpr,
            );

            // Минутная стрелка
            ctx.moveTo(centerX, centerY);
            ctx.lineTo(
                centerX + Math.cos(degToRad(date.getMinutes() * 6 - clockOffset)) * getResponsiveDimension(448) * dpr,
                centerY + Math.sin(degToRad(date.getMinutes() * 6 - clockOffset)) * getResponsiveDimension(448) * dpr,
            );
            ctx.closePath();

            // Секундная стрелка
            ctx.moveTo(centerX, centerY);
            ctx.lineTo(
                centerX + Math.cos(time - startTime.current) * getResponsiveDimension(590) * dpr,
                centerY + Math.sin(time - startTime.current) * getResponsiveDimension(590) * dpr,
            );
            ctx.closePath();
            ctx.stroke();

            // Пунткирные линии
            ctx.beginPath();
            ctx.globalAlpha = 0.3;
            ctx.lineWidth = dpr * 12;
            ctx.setLineDash([dpr, 23 * dpr]);

            for (let i = 0; i < 3; i++) {
                const angle = degToRad(degsDict[i] - ((wheelLerpedPointerX.current / 2) * dpr - centerX) * 0.0025);
                const startX = centerX;
                const startY = centerY;
                const endX = centerX + Math.cos(angle) * radius * dpr;
                const endY = centerY + Math.sin(angle) * radius * dpr;
                ctx.moveTo(startX, startY);
                ctx.lineTo(endX, endY);
            }
            ctx.stroke();

            ctx.globalAlpha = 1;
            ctx.lineWidth = dpr;
            ctx.setLineDash([]);
            drawCircle(centerX, centerY, getResponsiveDimension(13) * dpr, ctx, COLORS.BLACK);
        }
    });

    return (
        <div className="error-page__canvas">
            <canvas ref={mergeRefs([ref, canvasRef])} width={width} height={height} className="error-page__canvas-el" />
        </div>
    );
};

export default ErrorPageCanvas;
